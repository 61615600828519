import VueGtag, { query } from "vue-gtag";
import type { Router } from "vue-router";

export const analytics = {
  init(app: any, router: Router) {
    app.use(
      VueGtag,
      {
        config: {
          id: import.meta.env.VITE_GOOGLE_ANALYTICS_ID,
          params: {
            anonymize_ip: true,
            allow_google_signals: true,
            send_page_view: true,
          },
        },
        pageTrackerEnabled: true,
        initCommands: [
          [
            "consent",
            "default",
            {
              analytics_storage: "denied",
            },
          ],
        ],
      },
      router
    );
  },

  methods: {
    enable() {
      // @ts-ignore
      query("consent", "update", {
        analytics_storage: "granted",
      });
    },

    disable() {
      // @ts-ignore
      query("consent", "update", {
        analytics_storage: "denied",
      });
    },

    trackEvent(eventName: string, eventParams?: Record<string, any>) {
      // @ts-ignore
      query("event", eventName, eventParams);
    },
  },
};
