import { router } from "@src/router";
import { State } from "@src/types/stores/auth.store";
import { useLoginStore } from "./login.store";
import { useRegisterStore } from "./register.store";
import { HTTPError } from "ky";
import AuthService from "@src/services/auth.service";
import { AcceptTimeClockingData, User } from "@src/types/services/auth.service";
import { useAppStore } from "./app.store";
import { RequiredAction } from "@src/types/services/responses";
import { actionsCallbackBus } from "@src/actions/ActionsBus";
import AgencyService from "@src/services/agency.service";
import { useLocalStorageRef } from "@src/composables/useLocalStorageRef";

export const useAuthStore = defineStore("auth", () => {
  const appStore = useAppStore();
  const loginStore = useLoginStore();
  const registerStore = useRegisterStore();

  const state = reactive<State>({
    agency: undefined,
    hasCsrf: false,
    backgroundLoaded: false,
    user: undefined,
    intended: undefined,
    deleteAccountOpen: false,
    wizardTypeCache: localStorage.getItem("wizardType") || null,
  });

  const { data: jobRedirect } = useLocalStorageRef<string>("job-redirect");

  const userName = computed(() =>
    state.user ? `${state.user.first_name} ${state.user.last_name}` : ""
  );

  function reset() {
    state.backgroundLoaded = false;
    state.user = undefined;

    loginStore.reset();
    registerStore.reset();
  }

  async function getCSRF() {
    if (!state.hasCsrf) {
      await AuthService.getCSRF();
      state.hasCsrf = true;
    }
  }

  async function setFromAgency(name: string) {
    state.agency = await AgencyService.lookupAgency(name);
    return !!state.agency;
  }

  async function logout() {
    await AuthService.logout();
    reset();
    router.push({ name: "login" });
  }

  const mePromise: Ref<Promise<User> | undefined> = ref();

  async function me(checkAgency = true): Promise<boolean | RequiredAction> {
    try {
      if (!mePromise.value) {
        mePromise.value = AuthService.me(
          state.agency?.slug && checkAgency
            ? { referer: state.agency.slug }
            : undefined
        );
      }

      const user = await mePromise.value;

      const storedType = localStorage.getItem("wizardType");
      if (storedType) {
        user.wizard_type = storedType as "wizard_hostess" | "wizard_global";
      }

      state.user = user;
      mePromise.value = undefined;

      if (state.user?.lang && appStore.locale !== state.user.lang) {
        appStore.locale = state.user.lang;
      }

      return true;
    } catch (e) {
      mePromise.value = undefined;
      if (e instanceof HTTPError) {
        switch (e.response.status) {
          case 400:
            const errorData = await e.response.clone().json();
            if (errorData.errors?.required_action) {
              const action: RequiredAction = errorData.errors.required_action;
              let listenerResolve: any = undefined;
              switch (action.code) {
                case "account.link_agency":
                  listenerResolve = await new Promise((resolve) => {
                    const stopListener = actionsCallbackBus.on((event) => {
                      if (event.code === "account.link_agency") {
                        stopListener();
                        resolve(event);
                      }
                    });
                  });
                  if (listenerResolve.data === "success") {
                    return await me();
                  }
                  return true;
              }
              return action;
            }
            return true;
          case 401:
            return false;
          default:
            throw e;
        }
      } else {
        throw e;
      }
    }
  }

  async function setProfilePicture(file: string) {
    if (state.user) {
      await AuthService.setProfilePicture({ file });
      await me(false);
    }
  }

  async function acceptTimeClocking(
    geolocation?: Partial<GeolocationCoordinates>
  ) {
    if (state.user?.time_clocking) {
      const data: AcceptTimeClockingData = {
        type: state.user.time_clocking.type,
        geolocation: {
          timestamp: `${Date.now()}`,
          coords: {
            speed: geolocation?.speed ? `${geolocation?.speed}` : null,
            heading: geolocation?.heading ? `${geolocation?.heading}` : null,
            accuracy: geolocation?.accuracy ? `${geolocation?.accuracy}` : null,
            latitude: geolocation?.latitude ? `${geolocation?.latitude}` : null,
            longitude: geolocation?.longitude
              ? `${geolocation?.longitude}`
              : null,
            altitude_accuracy: geolocation?.altitudeAccuracy
              ? `${geolocation?.altitudeAccuracy}`
              : null,
          },
        },
      };

      try {
        await AuthService.acceptTimeClocking(data);
        return "success";
      } catch (e) {
        if (e instanceof HTTPError && e.response.status === 409) {
          return "not_exists";
        }

        throw e;
      }
    }
  }

  async function updateWizardType(type: "wizard_hostess" | "wizard_global") {
    if (!state.user) return;

    localStorage.setItem("wizardType", type);
    state.user.wizard_type = type;

    mePromise.value = undefined;
    await me(false);
  }

  function restoreWizardType() {
    if (state.user && state.wizardTypeCache) {
      state.user.wizard_type = state.wizardTypeCache;
    }
  }

  return {
    ...toRefs(state),
    jobRedirect,
    userName,
    setFromAgency,
    reset,
    getCSRF,
    logout,
    me,
    setProfilePicture,
    acceptTimeClocking,
    updateWizardType,
    restoreWizardType,
  };
});
